import {  UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ClientSettingInfo } from "../api/data-contracts";
import { clientSettingService } from "../services/client-setting.service";

export function clientSettingQuery(
): UseQueryOptions<ClientSettingInfo, AxiosError, ClientSettingInfo, readonly ["clientSetting"]> {
  return {
    queryKey: ["clientSetting"] as const,
    queryFn: async () =>      await clientSettingService.getClientSetting(),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: true,
    retry: false
  };
}
 
