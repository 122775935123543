import { ClientSettingInfo } from "../api/data-contracts";
import { ClientSetting } from "../api/ClientSetting";

const clientSettingApi = new ClientSetting();

export const clientSettingService = {
  getClientSetting: async (): Promise<ClientSettingInfo> => {
    const result = await clientSettingApi.clientSettingList();
    return result.data
  },
};
