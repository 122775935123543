import { SpManagement } from "../api/SpManagement";
import { SP, SPWithoutId, UserProfile } from "../api/data-contracts";
import { FilterParam, QueryParams, ServiceGetResult } from "../models/requests/shared.request";
import { SortParam } from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";
import { stripBase64Prefix } from "../util/shared.util";
import { sortParamsToString } from "../util/sorting.util";

const tspApi = new SpManagement();
addAuthHandling(tspApi);

export type TspsSortFilterParamName = "tspId";
export type TspsSortParam = SortParam<TspsSortFilterParamName>;
export type TspsFilterParam = FilterParam<TspsSortFilterParamName>;

export type GetTspsParams = QueryParams<TspsSortParam, TspsFilterParam>;
export type GetTspsResult = ServiceGetResult<SP[]>;

export type UpdateTspParams = SPWithoutId & { tspId: number };
export type DeleteTspParams = { tspId: number };
export type PutProfileParams = UserProfile & { id: number };

export const tspService = {
  addTsp: async ({ logo, ...rest }: SPWithoutId) =>
    tspApi.addSp({ logo: logo ? stripBase64Prefix(logo) : undefined, ...rest }),

  getTsps: async ({ paging, sorts, filters }: GetTspsParams): Promise<GetTspsResult> => {
    const result = await tspApi.getSpList({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters) : undefined,
      page: paging.pageIndex,
      pageSize: paging.pageSize,
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }

    return {
      data: result.data.data,
      pageCount,
    };
  },

  deleteTsp: async ({ tspId }: DeleteTspParams) => tspApi.deleteSp(tspId),

  updateTsp: async ({ tspId, logo, ...rest }: UpdateTspParams) =>
    tspApi.updateSp(tspId, { logo: logo ? stripBase64Prefix(logo) : undefined, ...rest }),

  updateTspProfile: async ({ id, ...rest }: PutProfileParams) => tspApi.updateProfile(id, rest),
  
};
