import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./CustomUnderlinedText.module.css";

export type UnderlinedTextProps = {
  type: "green" | "red" | "normal";
  text: string;
};

const CustomUnderlinedText = ({ text, type }: UnderlinedTextProps) => {
  return (
    <div className={`${styles.customUnderlinedText} text-start`}>
      <Row>
        <Col>
          <span className={`${styles["text"]} ${styles[type]}`}>{text}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.underline}></div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomUnderlinedText;
