import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToastContext } from "../context/ToastContext";
import { provisionDevicesMutation } from "../mutations/device.mutation";
import { AxiosResponse } from "axios";
import { ProvisionDevices } from "../api/data-contracts";

interface UseDeviceProps {
    onSuccessProvisionDevices?: (response: AxiosResponse<any>) => void;
}

function useDevice({
  onSuccessProvisionDevices,
}: UseDeviceProps = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();
  const { mutate: mutateProvisionDevices, isLoading, isError } = useMutation(provisionDevicesMutation());

  async function provisionDevices(data: ProvisionDevices): Promise<any> {
    return new Promise((resolve, reject) => {
      mutateProvisionDevices(
        { data: data },
        {
          onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: ["devices"] });
            addToast({ type: "POSITIVE", content: `Provision Devices Success` });
  
            onSuccessProvisionDevices?.(response);
            resolve(response.data);
          },
          onError: (error) => {
            let { response } = error;
            let data = response?.data as any;
            let message = data?.Exception?.Message;
            addToast({ type: "NEGATIVE", content: `Provision Devices Failed ${message ? message : ""}` });
            reject(error);
          },
        }
      );
    });
  }
  



  return {
    provisionDevices,
    isLoading,
    isError,
  };
}

export default useDevice;
