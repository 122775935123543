import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { AssignToFleetParams, devicesService, IUnassignDevice } from "../services/devices.service";

export const AssignDeviceMutation = (): UseMutationOptions<AxiosResponse, AxiosError, string> => ({
  mutationKey: ["assignDevice"],
  mutationFn: (deviceId: string) => devicesService.assignDevice(deviceId),
});

export const UnassignDeviceMutation = (): UseMutationOptions<AxiosResponse, AxiosError, IUnassignDevice> => ({
  mutationKey: ["unassignDevice"],
  mutationFn: (params: IUnassignDevice) => devicesService.unassignDevice(params),
});

type AssignToFleetOptions = UseMutationOptions<AxiosResponse<void>, AxiosError, AssignToFleetParams>;
export const assignToFleetMutation = (
  options: Omit<AssignToFleetOptions, "mutationKey" | "mutationFn">
): AssignToFleetOptions => ({
  mutationKey: ["assignDeviceToFleet"],
  mutationFn: (params: AssignToFleetParams) => devicesService.assignToFleet(params),
  ...options,
});

type UnassignFleetOptions = UseMutationOptions<AxiosResponse<void>, AxiosError, string>;
export const unassignFleetMutation = (
  options: Omit<UnassignFleetOptions, "mutationKey" | "mutationFn">
): UnassignFleetOptions => ({
  mutationKey: ["assignDeviceToFleet"],
  mutationFn: (deviceId: string) => devicesService.unassignFleet(deviceId),
  ...options,
});

type ActivateDeviceOptions = UseMutationOptions<AxiosResponse<void>, AxiosError, string>;
export const activateDeviceMutation = (
  options: Omit<ActivateDeviceOptions, "mutationKey" | "mutationFn">
): ActivateDeviceOptions => ({
  mutationKey: ["activateDevice"],
  mutationFn: (deviceId: string) => devicesService.activateDevice(deviceId),
  ...options,
});

type DeactivateDeviceOptions = UseMutationOptions<AxiosResponse<void>, AxiosError, string>;
export const deactivateDeviceMutation = (
  options: Omit<DeactivateDeviceOptions, "mutationKey" | "mutationFn">
): DeactivateDeviceOptions => ({
  mutationKey: ["deactivateDevice"],
  mutationFn: (deviceId: string) => devicesService.deactivateDevice(deviceId),
  ...options,
});


export const provisionDevicesMutation = (): UseMutationOptions<
  AxiosResponse,
  AxiosError,
  { data: any}
> => ({
  mutationKey: ["provisionDevices"],
  mutationFn: ({ data }: { data: any }) => devicesService.provisionDevices(data),
});

