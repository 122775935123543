/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AddFleet, Fleet, FleetCompanyProfile, GetListResponseFleet, MessageId } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class FleetManagement<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description The API retrieves a list of Fleets provisioned on the middleware. Each item in the list includes details about the Fleet , such as the Fleet  id, last event, etc.
   *
   * @tags Fleet Management
   * @name GetFleets
   * @summary Get list of registered Fleets
   * @request GET:/fleet
   * @secure
   */
  getFleets = (
    query?: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseFleet, void>({
      path: `/fleet`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get the count new fleet created based on the given fleetId. .
   *
   * @tags Fleet Management
   * @name GetFleetCount
   * @summary Get New Fleet Count.
   * @request GET:/fleet/{tspId}/{fleetId}
   * @secure
   */
  getFleetCount = (fleetId: number, tspId: number, params: RequestParams = {}) =>
    this.request<MessageId, void>({
      path: `/fleet/${tspId}/${fleetId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Add a new Fleet to middleware.
   *
   * @tags Fleet Management
   * @name AddFleet
   * @summary Add a new Fleet
   * @request POST:/fleet/{tspId}
   * @secure
   */
  addFleet = (tspId: number, data: AddFleet, params: RequestParams = {}) =>
    this.request<Fleet, void>({
      path: `/fleet/${tspId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Update the data of the Fleet.
   *
   * @tags Fleet Management
   * @name UpdateFleet
   * @summary Update a Fleet data
   * @request PUT:/fleet/{fleetId}
   * @secure
   */
  updateFleet = (fleetId: number, data: Fleet, params: RequestParams = {}) =>
    this.request<Fleet, void>({
      path: `/fleet/${fleetId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Remove a Fleet from the middleware registry.
   *
   * @tags Fleet Management
   * @name DeleteFleet
   * @summary Delete a Fleet
   * @request DELETE:/fleet/{fleetId}
   * @secure
   */
  deleteFleet = (fleetId: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/fleet/${fleetId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Retrieve basic information about the Fleet
   *
   * @tags Fleet Management
   * @name GetFleet
   * @summary Retrieve Fleet's information
   * @request GET:/fleet/{fleetId}
   * @secure
   */
  getFleet = (fleetId: number, params: RequestParams = {}) =>
    this.request<Fleet, void>({
      path: `/fleet/${fleetId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update the Fleet profile.
   *
   * @tags Fleet Management
   * @name UpdateFleetProfile
   * @request POST:/fleet/{fleetId}/profile
   * @secure
   */
  updateFleetProfile = (fleetId: number, data: FleetCompanyProfile, params: RequestParams = {}) =>
    this.request<FleetCompanyProfile, void>({
      path: `/fleet/${fleetId}/profile`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
