import { FormattedMessage, useIntl } from "react-intl";
import { useQueryAllDevices } from "../../hooks/useQueryDevices";
import { EMPTY_ARR, identity } from "../../util/shared.util";
import { useMemo } from "react";
import MultiSelectFilter from "../Filters/MultiSelectFilter";
import { DevicesSortFilterParamName } from "../../services/devices.service";
import { InputUi } from "../../constants";
import { DEVICES_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import addIconPath from "../../assets/icons/Add.svg";
import { useAuthContext } from "../../context/AuthContext";
import { Role } from "../../api/data-contracts";

interface Props {
  devicesCount?: number;
  onClickMute?: () => void;
  onClickAdd?: () => void;
  isMuteDisabled?: boolean;
}

function DevicesTableHeader({ devicesCount, onClickMute, isMuteDisabled, onClickAdd }: Props) {
  const { dataAll, isLoading: isLoadingDevices, isError: isErrorDevices } = useQueryAllDevices([], identity, true, true);
  const { formatMessage } = useIntl();
  const role = useAuthContext().loggedUser?.role;

  const deviceIdOptions = useMemo(() => {
    if (isLoadingDevices || isErrorDevices) return EMPTY_ARR;
    const deviceIdOptions = [];
    for (const device of dataAll?.data || []) {
      if (device.id) deviceIdOptions.push({ value: device.id, label: device.id });
    }
    return deviceIdOptions;
  }, [dataAll, isLoadingDevices, isErrorDevices]);


  return (
    <div className="d-flex align-items-center gap-5 px-1" style={{ height: "4.25rem" }}>
      <div className="header-font">
        <FormattedMessage id="DEVICES" defaultMessage="Devices" />
        {` (${devicesCount || 0})`}
      </div>
      {/* <div className="d-flex align-items-center gap-3">
        {getIsFleetRole(role) && (
          <ActionButton icon={<MuteIcon />} disabled={isMuteDisabled} onClick={onClickMute}>
            <FormattedMessage id="MUTE_DEVICE" defaultMessage="Mute Device" />
          </ActionButton>
        )}
      </div> */

        role !== Role.FleetManager && role !== Role.FleetUser &&
        <ActionButton icon={<img src={addIconPath} alt="add" />} onClick={onClickAdd}>
          <FormattedMessage id="PROVISION" defaultMessage="Provision" />
        </ActionButton>

      }

      <div className="ms-auto">
        <MultiSelectFilter<DevicesSortFilterParamName>
          context={DEVICES_CONTEXT}
          paramName="id"
          operator="=="
          options={deviceIdOptions}
          isLoading={isLoadingDevices}
          placeHolder={formatMessage({ id: "ALL", defaultMessage: "All" })}
          width={InputUi.MULTI_STD_WIDTH}
          inputLabel={formatMessage({ id: "DEVICE_ID", defaultMessage: "Device ID" })}
          includeInUrl
        />
      </div>
    </div>
  );
}

export default DevicesTableHeader;
