import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import hidePasswordIcon from "../../../assets/hide_password.svg";
import showPasswordIcon from "../../../assets/show_password.svg";

import { ChangeEvent, forwardRef, HTMLInputTypeAttribute, KeyboardEvent, RefObject, useEffect, useState } from "react";
import styles from "./TextInput.module.css";
import InputGroup from "react-bootstrap/InputGroup";

type BsFormProps = Omit<
  FormControlProps,
  "bsCustomPrefix" | "htmlSize" | "plaintext" | "value" | "onChange" | "custom" | "type" | "isValid" | "bsPrefix"
>;

export type TextInputProps = {
  className?: string;
  value?: string | null;
  defaultValue?: string | null;
  type?: HTMLInputTypeAttribute;
  textareaRows?: number;
  placeholder?: string;
  onEnterKey?: () => void;
  onEscKey?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  maxlength?: number;
  pattern?: string;
  title?: string;
  passwordToggleRef?: RefObject<HTMLDivElement>;
  inputLabel?: string;
  isNumeric?: boolean;
  showSuffixText?: boolean;
  suffixText?: string;
} & BsFormProps;

export const TextInput = forwardRef(
  (
    {
      type,
      value,
      defaultValue,
      placeholder,
      isInvalid,
      className,
      textareaRows,
      disabled,
      onChange,
      onEnterKey,
      onEscKey,
      maxlength,
      pattern,
      title,
      passwordToggleRef,
      inputLabel,
      isNumeric,
      showSuffixText,
      suffixText,
      ...rest
    }: TextInputProps,
    ref
  ) => {
    const TEXT = "text";
    const [inputType, setInputType] = useState<HTMLInputTypeAttribute>(type ?? "text");
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
      if (type !== "password") return;
      setInputType(showPassword ? "text" : "password");
    }, [type, showPassword]);

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      if (isNumeric && !/^[0-9\b]*$/.test(e.target.value)) {
        e.preventDefault();
        return;
      }
      onChange?.(e);
    };

    const togglePasswordHandler = () => {
      setShowPassword((prev) => !prev);
    };

    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === "Enter" && onEnterKey) {
        onEnterKey();
        return;
      }

      if (e.key === "Escape" && onEscKey) {
        onEscKey();
        return;
      }
    };

    const isTextarea = inputType === "textarea";

    return (
      <div className="input-with-label-container">
        {inputLabel && <div className="input-label">{inputLabel}</div>}
        <div className="position-relative">
          {type === "password" && (
            <div
              ref={passwordToggleRef}
              data-shown-state={showPassword ? "shown" : "hidden"}
              className={`${styles.passwordToggle} cursor-pointer`}
              onClick={togglePasswordHandler}
              title={!showPassword ? "Show Password" : "Hide Password"}
            >
              <img className="img-fluid" src={!showPassword ? showPasswordIcon : hidePasswordIcon} alt="" />
            </div>
          )}

          <InputGroup>
          <Form.Control
            ref={ref}
            as={isTextarea ? "textarea" : undefined}
            className={`app-form-input ${styles.input} ${className} ${isInvalid && "invalid"}
          ${disabled && "disabled"} ${value && "with-text"} 
          ${isTextarea && "textarea"}`}
            type={isTextarea ? TEXT : inputType ?? TEXT}
            value={value ?? ""}
            onChange={onChangeHandler}
            size="sm"
            rows={textareaRows}
            placeholder={!defaultValue ? placeholder : undefined}
            autoComplete={"new-password"}
            onKeyDown={keyDownHandler}
            maxLength={maxlength}
            pattern={pattern}
            title={title}
            disabled={disabled}
            {...rest}
          />
          {showSuffixText && <InputGroup.Text className={styles.inputGroupText}>{suffixText}</InputGroup.Text>}
          </InputGroup>
        </div>
      </div>
    );
  }
);
