import { useVehiclesQueryParamsContext } from "../../context/QueryParamsContext/VehiclesQueryParamsContextProvider";
import Table from "../Table/Table";
import styles from "./VehiclesTable.module.scss";
import Pagination from "../Table/Pagination";
import useQueryDevices from "../../hooks/useQueryDevices";

import { useCallback, useMemo, useReducer, useState } from "react";
import { devicesToVehicles, generateColumns, onClickRow } from "./vehicles-table.util";
import { Row } from "react-table";
import { EMPTY_ARR } from "../../util/shared.util";
import VehiclesControl from "./VehiclesControl";
import { modalInitialState, modalReducer } from "../../util/reducers/modal-reducer.util";
import MuteModal from "./modals/MuteModal";
import UnmuteModal from "./modals/UnmuteModal";
import UnpairModal from "./modals/UnpairModal";
import { Status } from "../../icons/StatusIcon";
import useMutationDevice from "../../hooks/useMutationDevice";
import { useAuthContext } from "../../context/AuthContext";
import ExpandedRow from "./ExpandedRow";
import VheicleCardModal from "./modals/VehicleCardModal";
import { DriversQueryParamsContextProvider } from "../../context/QueryParamsContext/DriversQueryParamsContextProvider";

type VehicleModalName = "MUTE" | "UNMUTE" | "UNPAIR" | "EDIT";
type VehicleModalData = VehicleEntry[] | VehicleEntry;

export interface VehicleEntry {
  vehicleType?: string;
  photo?: string;
  licensePlate?: string;
  modelName?: string;
  manufYear?: number;
  deviceId?: string;
  createdDateTime?: string;
  status?: Status;
  fleetName?: string;
  fullData?: object;
  vehicleId?: string | null;
}

export const VehiclesTable = () => {
  const { queryParams, setPageIndex } = useVehiclesQueryParamsContext();
  const { data, isError, isLoading, isFetching } = useQueryDevices(devicesToVehicles, true,true,false);
  const { mute, unmute } = useMutationDevice();
  const [selectedRows, setSelectedRows] = useState<Row<VehicleEntry>[]>([]);
  const selectedVehicles = useMemo(() => selectedRows.map((row) => row.original), [selectedRows]);
  const [modalState, dispatch] = useReducer(modalReducer<VehicleModalName, VehicleModalData>, modalInitialState);
  const { loggedUser } = useAuthContext();

  const handleUnmute = useCallback(
    (vehicle: VehicleEntry) =>
      dispatch({
        type: "SET_SHOW",
        payload: { modalName: "UNMUTE", data: vehicle },
      }),
    []
  );

  const handleUnpair = useCallback(
    (vehicle: VehicleEntry) =>
      dispatch({
        type: "SET_SHOW",
        payload: { modalName: "UNPAIR", data: vehicle },
      }),
    []
  );

  const columns = useMemo(
    () => generateColumns(handleUnmute, handleUnpair, loggedUser?.role),
    [handleUnmute, handleUnpair, loggedUser?.role]
  );

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-3">
        <VehiclesControl
          vehiclesCount={data?.totalCount}
          selectedRows={selectedRows}
          onClickEdit={() =>
            dispatch({
              type: "SET_SHOW",
              payload: { modalName: "EDIT", data: selectedVehicles },
            })
          }
          onClickMute={() =>
            dispatch({
              type: "SET_SHOW",
              payload: { modalName: "MUTE", data: selectedVehicles },
            })
          }
        />
        <Table
          data={data?.data || EMPTY_ARR}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => `${row.isSelected ? "selected-row-bg" : ""} ${styles.tr}`,
            td: (row) => `${styles.td} ${row.isExpanded ? "border-bottom-0" : ""}`,
          }}
          isLoading={isLoading}
          isError={isError}
          onChangeSelectedRows={setSelectedRows}
          onClickRow={onClickRow}
          renderRowSubComponent={(row, table) => <ExpandedRow row={row} table={table} />}
        />
      </div>
      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={data?.pageCount}
        allDisabled={isFetching}
        totalEntries={data?.totalCount}
      />
      <MuteModal
        show={modalState.shownModal === "MUTE"}
        multiple={selectedVehicles.length > 1}
        onClickNo={() => dispatch({ type: "CLEAR" })}
        onClickYes={() => {
          selectedVehicles
            .filter((vehicle: VehicleEntry) => vehicle.vehicleId)
            .forEach((vehicle: VehicleEntry) => {
              mute({ deviceId: vehicle.deviceId! });
            });
          dispatch({ type: "CLEAR" });
        }}
      />
      <UnmuteModal
        show={modalState.shownModal === "UNMUTE"}
        onClickNo={() => dispatch({ type: "CLEAR" })}
        onClickYes={() => {
          unmute((modalState.data as VehicleEntry).deviceId!);
          dispatch({ type: "CLEAR" });
        }}
      />
      <UnpairModal
        show={modalState.shownModal === "UNPAIR"}
        onClickNo={() => dispatch({ type: "CLEAR" })}
        onClickYes={() => {
          mute({ deviceId: (modalState.data as VehicleEntry).deviceId!, unpair: true });
          dispatch({ type: "CLEAR" });
        }}
      />
      <DriversQueryParamsContextProvider>
        <>
          {modalState.shownModal === "EDIT" && (
            <VheicleCardModal
              show={modalState.shownModal === "EDIT"}
              onClickClose={() => dispatch({ type: "CLEAR" })}
              vehicleInitialData={selectedRows[0]?.original}
            />
          )}
        </>
      </DriversQueryParamsContextProvider>
    </div>
  );
};

export default VehiclesTable;
