import { useQuery } from "@tanstack/react-query";
import { GetTspsParams } from "../services/tsp.service";
import { tspsQuery } from "../queries/tsps.query";

const queryParams: GetTspsParams = { filters: [], sorts: [], paging: { pageIndex: 1, pageSize: 1000 } };

interface UseQueryTsps {
  enabled: boolean;
}

function useQueryTsps({ enabled }: UseQueryTsps) {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(tspsQuery(queryParams, enabled));

  return { data, isLoading, isError, isFetching, refetch };
}

export default useQueryTsps;
