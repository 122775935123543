/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DeviceSettings,
  Error,
  FleetId,
  FleetSenseDevice,
  GetListResponseFleetSenseDevice,
  ProvisionDevices,
  ProvisionDevicesResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class DeviceManagement<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description The API retrieves a list of devices provisioned on the middleware. Each item in the list includes details about the device, such as the device id, list event, etc.
   *
   * @tags Device Management
   * @name GetDevices
   * @summary Get list of registered devices
   * @request GET:/device
   * @secure
   */
  getDevices = (
    query?: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
      /**
       * A boolean indicating whether the server should resolve geolocation information
       * to addresses. If true, the server will include address information for each
       * device in the list.
       */
      geocoding?: boolean;
      /** @default false */
      includeInstallationReport?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseFleetSenseDevice, Error>({
      path: `/device`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Register multiple FS10 devices to the FSCloud server. The request will take one Fleet Id and one Tsp Id and multiple devices.
   *
   * @tags Device Management
   * @name ProvisionDevices
   * @summary Register Devices
   * @request POST:/device
   * @secure
   */
  provisionDevices = (data: ProvisionDevices, params: RequestParams = {}) =>
    this.request<ProvisionDevicesResponse, void>({
      path: `/device`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description update device's settings. Device is not required to be online for this.
   *
   * @tags Device Management
   * @name UpdateDevice
   * @summary update device
   * @request PUT:/device/{deviceId}
   * @secure
   */
  updateDevice = (deviceId: string, data: DeviceSettings, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Add a new FS10 device to the middleware.<br>Before a device can connect and send events to the middleware, it must be registered via this API.
   *
   * @tags Device Management
   * @name AddDevice
   * @summary Add new device
   * @request POST:/device/{deviceId}
   * @secure
   */
  addDevice = (deviceId: string, data: DeviceSettings, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Remove a device from the middleware registry. After the device is deleted, it will not be able to connect and send further events. All events, videos, and images stored for the device are deleted from the server.
   *
   * @tags Device Management
   * @name DeleteDevice
   * @summary Delete device.
   * @request DELETE:/device/{deviceId}
   * @secure
   */
  deleteDevice = (deviceId: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Retrieve basic information about a device and the last event that was received from it.
   *
   * @tags Device Management
   * @name GetDevice
   * @summary get device's info and last event
   * @request GET:/device/{deviceId}
   * @secure
   */
  getDevice = (
    deviceId: string,
    query?: {
      includeDrivers?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<FleetSenseDevice, void>({
      path: `/device/${deviceId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Assigning device to vehicle - renew the communication of the device
   *
   * @tags Device Management
   * @name AssignDevice
   * @summary assign/unmute device
   * @request PUT:/device/{deviceId}/assign
   * @secure
   */
  assignDevice = (deviceId: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}/assign`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Unassign device from vehicle - stop the communication of the device. Can disconnect the device from the vehicle forever (unpair)
   *
   * @tags Device Management
   * @name UnassignDevice
   * @summary unassign/unpair (unassign forever) device
   * @request PUT:/device/{deviceId}/unassign
   * @secure
   */
  unassignDevice = (
    deviceId: string,
    query?: {
      unpair?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/device/${deviceId}/unassign`,
      method: "PUT",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description activate device
   *
   * @tags Device Management
   * @name ActivateDevice
   * @summary activate device
   * @request PUT:/device/{deviceId}/activate
   * @secure
   */
  activateDevice = (deviceId: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}/activate`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description deactivate device
   *
   * @tags Device Management
   * @name DeactivateDevice
   * @summary deactivate device
   * @request PUT:/device/{deviceId}/deactivate
   * @secure
   */
  deactivateDevice = (deviceId: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}/deactivate`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Device Management
   * @name AssignToFleet
   * @summary assing device to fleet
   * @request POST:/device/{deviceId}/fleet/{fleetId}
   * @secure
   */
  assignToFleet = (deviceId: string, fleetId: FleetId, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}/fleet/${fleetId}`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Device Management
   * @name UnassignFleet
   * @summary unassing device to fleet
   * @request DELETE:/device/{deviceId}/fleet
   * @secure
   */
  unassignFleet = (deviceId: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}/fleet`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Deallocate FS10 device from an SP. Deallocated FS10 devices are tagged as 'Inventory' until they are associated with a vehicle. The device does not need to be online for this operation to succeed.
   *
   * @tags Device Management
   * @name DeprovisionDevice
   * @summary Deallocate FS10 device from an SP
   * @request DELETE:/device/{deviceId}/provision
   * @secure
   */
  deprovisionDevice = (deviceId: string, params: RequestParams = {}) =>
    this.request<any, Error>({
      path: `/device/${deviceId}/provision`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Allocate FS10 devices to an existing SP. Allocated FS10 devices are tagged as 'Inventory' until they are associated with a vehicle. The device does not need to be online for this operation to succeed.
   *
   * @tags Device Management
   * @name ProvisionDevice
   * @summary Allocate existing FS10 devices to an existing SP
   * @request PUT:/device/{deviceId}/provision/{tspId}
   * @secure
   */
  provisionDevice = (
    deviceId: string,
    tspId: number,
    data: {
      /** The id of the SP to allocate the device to */
      tspId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<any, Error>({
      path: `/device/${deviceId}/provision/${tspId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
