import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useCallback, useContext, useState } from "react";
import { clientSettingQuery } from "../queries/clientSetting.query";

interface ILastUpdateContext {
  time: string;
  autoRefreshIntervalInSec: number;
  invalidateQueries: () => void;
  updateTime: () => void;
}

const LastUpdateContext = createContext<ILastUpdateContext>({
  time: new Date().toLocaleTimeString(),
  autoRefreshIntervalInSec: 60,
  invalidateQueries: () => { },
  updateTime: () => { },
});

export function LastUpdateContextProvider({ children }: PropsWithChildren<{}>) {
  const queryClient = useQueryClient();
  const { data } = useQuery(clientSettingQuery());
  const [time, setTime] = useState<string>(new Date().toISOString());

  const invalidateQueries = useCallback(async () => {
    await queryClient.invalidateQueries();
    setTime(new Date().toISOString());
  }, [queryClient]);

  const updateTime =  async () => {
    setTime(new Date().toISOString());
  };
  return <LastUpdateContext.Provider value={{ time, autoRefreshIntervalInSec: data?.autoRefreshIntervalInSec ?? 60, invalidateQueries, updateTime }}>{children}</LastUpdateContext.Provider>;
}

export const useLastUpdateContext = () => useContext(LastUpdateContext);
